<template>
  <router-view v-if="isRouterAlive"></router-view>
  <Loading v-if="sloader || cloader" />
</template>

<script>
import Loading from './components/Loading.vue';
import { mapState } from 'vuex';
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  name: 'App',
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  components: {
    Loading,
  },
  computed: {
    ...mapState('schoolsubmit', ['sloader']),
    ...mapState('centersubmit', ['cloader']),
  },
}
</script>
