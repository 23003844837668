<script>
import { mapState } from 'vuex';
export default {
  name: 'Loading',
  computed: {
    ...mapState('schoolsubmit', ['sloader']),
    ...mapState('centersubmit', ['cloader']),
    show() {
      return this.sloader || this.cloader;
    }
  },
};

</script>

<template>
  <!-- loding -->
  <div v-if="show" style="
      z-index: 9998;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      font-size: 48px;
      align-items: center;
      background-color: black;
      opacity: 60%;
    ">
    <b-spinner></b-spinner>
    <span class="fs-2">Loading...</span>
  </div>

  <!-- <div v-if="show" class="loading-overlay">
    <b-spinner type="grow" label="Loading...">123</b-spinner>
    <span>123</span>
  </div> -->
</template>

<!-- <style scoped>
.loading-overlay {
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 48px;
  align-items: center;
  background-color: black;
  opacity: 0.5;
  /* 使用0.5来表示50%的不透明度 */
}
</style> -->