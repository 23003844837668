export default [
  // 重定向根目錄到最新消息
  {
    path: "/",
    redirect: "/Default",
  },
  //根目錄
  {
    path: "/Comments",
    name: "個人辭庫管理",
    component: () => import("./views/dashboards/Comments"),
  },
  {
    path: "/Default",
    name: "最新消息",
    component: () => import("./views/Front/Default"),
  },
  {
    path: "/Introduction",
    name: "中心簡介",
    component: () => import("./views/Front/Introduction"),
  },
  {
    path: "/Service",
    name: "服務項目",
    component: () => import("./views/Front/Service"),
  },
  {
    path: "/Blue",
    name: "青探號",
    component: () => import("./views/Front/Blue"),
  },
  {
    path: "/Adaptive",
    name: "適性輔導",
    component: () => import("./views/Front/Adaptive"),
  },
  {
    path: "/Download",
    name: "表單下載",
    component: () => import("./views/Front/Download"),
  },
  {
    path: "/Counseling",
    name: "輔導園地",
    component: () => import("./views/Front/Counseling"),
  },
  {
    path: "/Activities",
    name: "活動花絮",
    component: () => import("./views/Front/Activities"),
  },
  {
    path: "/Problem",
    name: "常見問題",
    component: () => import("./views/Front/Problem"),
  },
  {
    path: "/CenterInfo",
    name: "CenterInfo",
    component: () => import("./views/Front/CenterInfo"),
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("./views/Account/Login"),
  },
  {
    path: "/Forgot-Password",
    name: "Forgot-Password",
    component: () => import("./views/Account/Forgot-Password"),
  },
  {
    path: "/rest-password",
    name: "Rest password",
    component: () => import("./views/Account/rest-password"),
  },
  {
    path: "/Activate",
    name: "Activate",
    component: () => import("./views/Account/Activate"),
  },
  {
    path: "/Logout",
    name: "Logout",
    meta: { authRequired: true, PageID: 44 },
    component: () => import("./views/Account/Logout"),
  },
  {
    path: "/Reset",
    name: "Reset",
    meta: { authRequired: true, PageID: 42 },
    component: () => import("./views/Account/Reset"),
  },
  {
    path: "/System",
    name: "System",
    meta: {
      title: "基隆學生輔導諮商中心 個案管理系統",
      authRequired: true,
      PageID: 2,
    },
    component: () => import("./views/dashboards/System"),
  },
  {
    path: "/CenDownload",
    name: "CenDownload",
    meta: { authRequired: true, PageID: 10 },
    component: () => import("./views/dashboards/CenDownload"),
  },
  {
    path: "/BackSetting",
    name: "BackSetting",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/BackSetting"),
  },
  {
    path: "/FrontSetting",
    name: "FrontSetting",
    meta: { authRequired: true },
    component: () => import("./views/FrontSetting/FrontSetting"),
  },
  {
    path: "/Statistical",
    name: "Statistical",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/Statistical"),
  },
  {
    path: "/IntroductionSetting",
    name: "IntroductionSetting",
    meta: { authRequired: true },
    component: () => import("./views/FrontSetting/IntroductionSetting"),
  },
  {
    path: "/CounselingSetting",
    name: "CounselingSetting",
    meta: { authRequired: true },
    component: () => import("./views/FrontSetting/CounselingSetting"),
  },
  //個案學校
  {
    path: "/Case",
    name: "Case",
    meta: { authRequired: true, PageID: 5 },
    component: () => import("./views/Case/Case"),
  },
  //個案學校新增
  {
    path: "/CaseAdd",
    name: "CaseAdd",
    meta: { authRequired: true, PageID: 4 },
    component: () => import("./views/Case/CaseAdd"),
  },
  //個案學校編輯
  {
    path: "/CaseEdit/:id/:id1/:id2?",
    name: "CaseEdit",
    meta: { PageID: 5 },
    component: () => import("./views/Case/CaseEdit"),
  },
  //個案中心
  {
    path: "/CaseCenter",
    name: "CaseCenter",
    meta: { authRequired: true, PageID: 8 },
    component: () => import("./views/CaseCenter/CaseCenter"),
  },
  //個案中心新增
  {
    path: "/CaseCenterAdd",
    name: "CaseCenterAdd",
    meta: { authRequired: true, PageID: 7 },
    component: () => import("./views/CaseCenter/CaseCenterAdd"),
  },
  //個案中心編輯
  {
    path: "/CaseCenterEdit/:id/:id1/:id2?",
    name: "CaseCenterEdit",
    meta: { PageID: 8 },
    component: () => import("./views/CaseCenter/CaseCenterEdit"),
  },
  //個案資料查詢
  {
    path: "/CaseSearch",
    name: "CaseSearch",
    meta: { authRequired: true, PageID: 9 },
    component: () => import("./views/CaseSearch/CaseSearch"),
  },
  //學校個案資料查詢的詳細資料
  {
    path: "/SchoolCaseSearchRead/:id/:id1",
    name: "SchoolCaseSearchRead",
    meta: { PageID: 9 },
    component: () => import("./views/CaseSearch/SchoolCaseSearchRead"),
  },
  //中心個案資料查詢的詳細資料
  {
    path: "/CenterCaseSearchRead/:id/:id1",
    name: "CenterCaseSearchRead",
    meta: { PageID: 9 },
    component: () => import("./views/CaseSearch/CenterCaseSearchRead"),
  },
  {
    path: "/News",
    name: "News",
    meta: { authRequired: true, PageID: 17 },
    component: () => import("./views/FrontSetting/News"),
  },
  //頁面建置中
  {
    path: "/Coming",
    name: "Coming",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/Coming"),
  },
  //中心資訊
  {
    path: "/CenterInformation",
    name: "CenterInformation",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/CenterInformation"),
  },
  //學校帳號管理
  {
    path: "/SchoolAccountManagement",
    name: "SchoolAccountManagement",
    meta: { authRequired: true, PageID: 1 },
    component: () => import("./views/Case/SchoolAccountManagement"),
  },
  //中心服務總表
  {
    path: "/CenterServiceSummary",
    name: "CenterServiceSummary",
    meta: { authRequired: true, PageID: 31 },
    component: () => import("./views/dashboards/CenterServiceSummary"),
  },
  //收案服務統計
  {
    path: "/CaseCollection",
    name: "CaseCollection",
    meta: { authRequired: true, PageID: 33 },
    component: () => import("./views/dashboards/CaseCollection"),
  },
  //個人月報表
  {
    path: "/PersonalMonthlyReport",
    name: "PersonalMonthlyReport",
    meta: { authRequired: true, PageID: 32 },
    component: () => import("./views/dashboards/PersonalMonthlyReport"),
  },
  //個案內部轉介
  {
    path: "/CaseInternalReferral",
    name: "CaseInternalReferral",
    meta: { authRequired: true, PageID: 28 },
    component: () => import("./views/dashboards/CaseInternalReferral"),
  },
  {
    //回覆單設定
    path: "/ReplyTemplate",
    name: "ReplyTemplate",
    meta: { authRequired: true, PageID: 41 },
    component: () => import("./views/dashboards/ReplyTemplate"),
  },
  //核銷資料
  {
    path: "/CaseScriberOff",
    name: "CaseScriberOff",
    meta: { authRequired: true, PageID: 45 },
    component: () => import("./views/ScriberOff/CaseScriberOff"),
  },
  {
    path: "/CaseScriberOffEdit/:id",
    name: "CaseScriberOffEdit/:id",
    meta: { authRequired: true, PageID: 45 },
    component: () => import("./views/ScriberOff/CaseScriberOffEdit"),
  },
  {
    //核銷資料設定
    path: "/ScriberOffSetting",
    name: "ScriberOffSetting",
    meta: { authRequired: true, PageID: 46 },
    component: () => import("./views/ScriberOff/ScriberOffSetting"),
  },
  {
    path: "/Inventory",
    name: "Inventory",
    meta: { authRequired: true, PageID: 38 },
    component: () => import("./views/dashboards/Inventory"),
  },
  {
    path: "/Application",
    name: "Application",
    meta: { authRequired: true, PageID: 40 },
    component: () => import("./views/dashboards/Application"),
  },
  {
    path: "/Borrowing",
    name: "Borrowing",
    meta: { authRequired: true, PageID: 39 },
    component: () => import("./views/dashboards/Borrowing"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/components/404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/components/500.vue"),
  },
];
