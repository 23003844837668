import ApiHelper from "@/helpers/ApiHelper";
import Swal from "sweetalert2";
import CenterEvaluation from "../../components/CaseCenter/Mental/CenterEvaluation.vue";
import MentalCheckapply from "../../components/CaseCenter/Mental/MentalCheckapply.vue";
import SocialCheckapply from "../../components/CaseCenter/Social/SocialCheckapply.vue";

export const state = {
  schoolList: [],
  caseData: {}, // 來自第一個組件的表單數據
  MemoData: "", // 來自第二個組件的意見數據
  Case_teacher_aid1: "", // 主責人員
  isDataReady: false, // 是否已經取得所有數據
  CaseTrend: "", // 案件趨勢
  中心評估: {}, // 中心評估
  心諮入校: {}, // 心諮組入校初評
  社工入校: {}, // 社工組入校初評
  cloader: false,
};
export const mutations = {
  setCaseData(state, payload) {
    state.caseData = payload;
  },
  setMemoData(state, payload) {
    state.MemoData = payload;
  },
  setCaseteacherData(state, payload) {
    state.Case_teacher_aid1 = payload;
  },
  setCaseTrend(state, payload) {
    state.CaseTrend = payload;
  },
  setDataReady(state, value) {
    state.isDataReady = value;
  },
  set中心評估(state, payload) {
    state.中心評估 = payload;
  },
  set心諮入校(state, payload) {
    state.心諮入校 = payload;
  },
  set社工入校(state, payload) {
    state.社工入校 = payload;
  },
  setSchoolList(state, payload) {
    state.schoolList = payload;
  },
  LOADER(state, cloader) {
    state.cloader = cloader;
  },
};

export const actions = {
  //中心開案
  async SubmitCase({ state, commit }, PageID) {
    // 組合來自不同組件的數據
    const allData = {
      Case_Master_aid: state.caseData.Aid,
      CaseForm: state.caseData,
      PageID: PageID,
    };
    commit("LOADER", true);
    // 送出資料
    await ApiHelper.Axios(
      "api/CaseCenter/ImplementCase",
      "POST",
      allData,
      (res) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        }).then(() => {
          location.href = "/System";
        });
      },
      (err) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "error",
          title: `${err}`,
        });
      },
      true
    );
  },
  //中心組長
  async LeaderSubmit({ dispatch, state, commit }, PageID) {
    // 組合來自不同組件的數據
    const allData = {
      Case_Master_aid: state.caseData.Aid,
      Memo: state.MemoData,
      Case_teacher_aid: state.Case_teacher_aid1,
      CaseTrend: state.CaseTrend,
      PageID: PageID,
      中心評估: state.中心評估,
      心諮入校: state.心諮入校,
      社工入校: state.社工入校,
    };
    commit("LOADER", true);
    // 送出資料
    await ApiHelper.Axios(
      "api/CaseCenter/LeaderSubmitCase",
      "POST",
      allData,
      (res) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        }).then(() => {
          location.href = "/System";
        });
      },
      (err) => {
        commit("LOADER", false);
        Swal.fire("", ApiHelper.ProcessErrMsg(err), "error").then(() => {
          if (err.data && err.data.Field !== "") {
            if (state.caseData.Case_Status === 208) {
              dispatch(
                "model/openModal",
                {
                  showModal: true,
                  activeModalContent: {
                    component: CenterEvaluation,
                    props: {
                      caseID: state.caseData.Aid,
                      casestatus: state.caseData.Case_Status,
                      SchoolList: state.schoolList,
                      PageID: PageID,
                    },
                  },
                  modalTitle: "中心初評表",
                },
                { root: true }
              );
            } else if (state.caseData.Case_Status === 306) {
              dispatch(
                "model/openModal",
                {
                  showModal: true,
                  activeModalContent: {
                    component:
                      allData.心諮入校.Case_Plan == "1"
                        ? MentalCheckapply
                        : SocialCheckapply,
                    props: {
                      caseID: state.caseData.Aid,
                      casestatus: state.caseData.Case_Status,
                      PageID: PageID,
                    },
                  },
                  modalTitle: "入校初評表",
                },
                { root: true }
              );
            }
          }
        });
      },
      true
    );
  },
  //中心主任
  async DirectorSubmit({ state, commit }, PageID) {
    // 組合來自不同組件的數據
    const allData = {
      Case_Master_aid: state.caseData.Aid,
      Memo: state.MemoData,
      PageID: PageID,
    };
    commit("LOADER", true);
    // 送出資料
    await ApiHelper.Axios(
      "api/CaseCenter/DirectorSubmitCase",
      "POST",
      allData,
      (res) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        }).then(() => {
          location.href = "/System";
        });
      },
      (err) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "error",
          title: `${err}`,
        });
      },
      false
    );
  },
  //中心人員
  async CenterStaffSubmit({ dispatch, state, commit }, PageID) {
    // 組合來自不同組件的數據
    const allData = {
      Case_Master_aid: state.caseData.Aid,
      Memo: state.MemoData,
      PageID: PageID,
      中心評估: state.中心評估,
      心諮入校: state.心諮入校,
      社工入校: state.社工入校,
    };
    commit("LOADER", true);
    // 送出資料
    await ApiHelper.Axios(
      "api/CaseCenter/CenterStaff",
      "POST",
      allData,
      (res) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        }).then(() => {
          location.href = "/System";
        });
      },
      (err) => {
        commit("LOADER", false);
        if (state.caseData.Case_Status === 205) {
          dispatch(
            "model/openModal",
            {
              showModal: true,
              activeModalContent: {
                component: CenterEvaluation,
                props: {
                  caseID: state.caseData.Aid,
                  casestatus: state.caseData.Case_Status,
                  SchoolList: state.schoolList,
                  PageID: PageID,
                },
              },
              modalTitle: "中心初評表",
            },
            { root: true }
          );
        } else if (state.caseData.Case_Status === 211) {
          dispatch(
            "model/openModal",
            {
              showModal: true,
              activeModalContent: {
                component:
                  state.caseData.Case_Plan == 2
                    ? SocialCheckapply
                    : MentalCheckapply,
                props: {
                  caseID: state.caseData.Aid,
                  casestatus: state.caseData.Case_Status,
                  PageID: PageID,
                },
              },
              modalTitle: "入校初評表",
            },
            { root: true }
          );
        }

        Swal.fire({
          icon: "error",
          title: `${err.message}`,
        });
      },
      true
    );
  },
  // 心諮組入校初評
  async MentalCheckapplySubmit({ state, commit }, PageID) {
    // 組合來自不同組件的數據
    const allData = {
      Case_Master_aid: state.caseData.Aid,
      Memo: state.MemoData,
      PageID: PageID,
    };
    commit("LOADER", true);
    // 送出資料
    await ApiHelper.Axios(
      "api/CaseCenter/CheckapplyMentalNucleus",
      "POST",
      allData,
      (res) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        }).then(() => {
          location.href = "/System";
        });
      },
      (err) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "error",
          title: `${err}`,
        });
      },
      false
    );
  },
  //心諮組確定受理
  async CheckOpenCase({ state, commit }, { PageID, reload }) {
    commit("LOADER", true);
    // 送出資料
    await ApiHelper.Axios(
      `api/CaseCenter/CheckOpenCase/${state.caseData.Aid}/${PageID}`,
      "GET",
      null,
      () => {
        commit("LOADER", false);
        reload();
      },
      (err) => {
        commit("LOADER", false);
        Swal.fire({
          icon: "error",
          title: `${err}`,
        });
      },
      false
    );
  },
};
