export const state = {
    WorkRecord: [],
};

export const mutations = {
  setWorkRecord(state, payload) {
    state.WorkRecord = payload;
  },
};

export const actions = {
};
