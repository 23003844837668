export const state = {
  showModal: false,
  showReturnModal: false,
  showCommentsModal: false,
  activeModalContent: {
    component: null,
    props: {},
  },
  modalTitle: "",
};
export const mutations = {
  setModalState(state, payload) {
    state.showModal = payload.showModal;
    state.activeModalContent = payload.activeModalContent;
    state.modalTitle = payload.modalTitle;
  },
  closeModal(state) {
    state.showModal = false;
    state.showCommentsModal = false;
    state.showReturnModal = false;
    state.showReturnModal = false;
    state.activeModalContent = {
      component: null,
      props: {},
    };
    state.modalTitle = "";
  },
  setReturnModal(state, payload) {
    state.showReturnModal = payload.showReturnModal;
    state.activeModalContent = payload.activeModalContent;
    state.modalTitle = payload.modalTitle;
  },
};

export const actions = {
  openModal({ commit }, payload) {
    commit("setModalState", payload);
  },
  openreturnModal({ commit }, payload) {
    commit("setReturnModal", payload);
  },
  closeModal({ commit }) {
    commit("closeModal");
  },
};
