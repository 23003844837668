export const state = {
    menuItems: []
  }
  
// 在您的 menu 模塊中
export const mutations = {
  setMenuItems(state, items) {
    state.menuItems = items;
  },
};
