import { createWebHistory, createRouter } from "vue-router";
import ApiHelper from "@/helpers/ApiHelper";
import routes from "./routes";
import store from "../state/store";
import Swal from "sweetalert2";
import Axios from "axios";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/components/404.vue"),
    },
  ],
  store,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

let skipAuthCheck = false; // 用來控制是否跳過 AuthCheck 的變數
const staticPage = new Set([
  "/Default",
  "/Problem",
  "/Service",
  "/Blue",
  "/Activities",
  "/Adaptive",
  "/Counseling",
  "/Download",
  "/Introduction",
  "/Login",
  "/Forgot-password",
  "/Activate",
  "/rest-password",
]);
async function fetchMenuItems() {
  try {
    const res = await Axios.get(`${process.env.VUE_APP_URL}/api/Menu/GetMenu`);
    store.commit("menu/setMenuItems", res.data);
  } catch (err) {
    Swal.fire(ApiHelper.ProcessErrMsg(err)).then(() => {
      router.replace("/Login");
    });
  }
}
async function handleAuthCheck(pageID) {
  return new Promise((resolve) => {
    ApiHelper.AuthCheck(pageID, (res) => {
      sessionStorage.setItem("ctk", res.data.Token);
      resolve();
    });
  });
}
async function handleLoginRoute(next) {
  return new Promise((resolve) => {
    ApiHelper.AuthCheck(
      2,
      () => {
        skipAuthCheck = true;
        location.href = "/System";
        resolve();
      },
      () => {
        next();
        resolve();
      }
    );
  });
}

router.beforeEach(async (routeTo, routeFrom, next) => {
  const { path } = routeTo;

  if (path === "/Login") {
    await handleLoginRoute(next);
    return next();
  }

  if (path === "/System" && skipAuthCheck) {
    skipAuthCheck = false; // 重置 skipAuthCheck
    if (store.state.menu.menuItems.length === 0) {
      await fetchMenuItems();
    }
    return next();
  }

  // Handle static pages
  if (staticPage.has(path)) {
    store.commit("menu/setMenuItems", []);
    return next();
  }

  if (!staticPage.has(path)) {
    await fetchMenuItems();
  }

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const menuItems = store.state.menu.menuItems;
  let pageID = null;
  for (const item of menuItems) {
    if (item.subItems && item.subItems.length > 0) {
      for (const subItem of item.subItems) {
        if (subItem.link === routeTo.path) {
          pageID = subItem.id;
          break;
        }
      }
    } else {
      if (item.link === routeTo.path) {
        pageID = item.id;
        break;
      }
    }
  }

  if (authRequired && pageID !== null) {
    await handleAuthCheck(pageID);
    return next();
  }

  next();
});

export default router;
