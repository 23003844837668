import axios from "axios";
import Swal from "sweetalert2";
import router from "../router";

/// 創建一個axios實例和設置攔截器
const axiosServices = axios.create();
// 設定請求攔截器
axiosServices.interceptors.request.use(
  (config) => {
    const ctk = sessionStorage.getItem("ctk");
    if (ctk) {
      config.headers["csrf-token"] = ctk;
    }
    return config;
  },
  (error) => {
    throw error;
  }
);
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    throw error;
  }
);
class CustomError extends Error {
  constructor(message, data, status) {
    super(message);
    this.data = data;
    this.status = status;
  }
}

const ApiHelper = {
  ApiMethod: {
    None: 0,
    Create: 1,
    Delete: 2, //4
    Read: 3, //2
    Update: 4, //3
  },
  SystemName: process.env.VUE_APP_NAME,
  Type: {
    None: 0,
    GetAll: 1,
    Get: 2,
    Create: 3,
    Modify: 4,
    Delete: 5,
    Move: 6,
    MailTest: 7,
    Mail: 8,
    Export: 9,
  },
  FrontType: {
    Default: 0,
    NewList: 1, //2
    New: 2, //3
    CenterDatalist: 3, //5
    MiscData: 4, //6
    CounselingDataList: 5, //9
    EventDataList: 6, //10
    DownloadDataList: 7, //11
    FaqDataList: 8, //12
    LinkDataList: 9, //13
    ExplorationDataList: 10, //17
    ServiceDataList: 11,
  },
  EnumType: {
    None: 0,
    NewsImageUpload: 1,
    NewsFileUpload: 2,
    NewsFileDownload: 3,
    MiscDataImageUpload: 4,
    MiscDataFileUpload: 5,
    MiscDataFileDownload: 6,
    MiscDataCoverImageUpload: 7,
  },
  FrontCategory: {
    中心簡介: 1,
    服務項目_工作任務: 2,
    服務項目_服務對象: 3,
    服務項目_服務方式: 4,
    活動成果: 5,
    表單下載_申請必要資料: 6,
    表單下載_個案服務表單: 7,
    表單下載_其他服務表單: 8,
    表單下載_行政表單: 9,
    常見問題: 10,
  },
  ProcessErrMsg: function (err) {
    return err.response ? err.response.data.Message : err.message;
  },
  handleScrollToError: function (err) {
    if (err.data && err.data.Field !== "") {
      const fieldId = err.data.Field;
      const element = document.getElementById(fieldId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 400);
        // 設定紅色邊框
        element.style.border = "2px solid red";
        // 動態新增一個 div 插入在錯誤欄位後面
        const errorMsgDiv = document.createElement("div");
        errorMsgDiv.className = "error-message";
        errorMsgDiv.textContent = err.data.Message;
        // 直接設定 CSS 樣式
        errorMsgDiv.style.color = "red";
        errorMsgDiv.style.fontWeight = "bold";
        errorMsgDiv.style.marginTop = "10px";
        // 插入錯誤訊息
        if (element.nextSibling) {
          element.parentNode.insertBefore(errorMsgDiv, element.nextSibling);
        } else {
          element.parentNode.appendChild(errorMsgDiv);
        }
        // 設定 2.5 秒後自動移除錯誤訊息和紅色邊框
        setTimeout(() => {
          errorMsgDiv.remove();
          element.style.border = ""; // 移除紅色邊框
        }, 2500);
      }
    }
  },
  HandleError: async function (error, Catch, shouldScrollToError) {
    // 如果提供了自定義的 Catch 函數，則先執行它
    if (Catch) {
      await Catch(error);
      if (shouldScrollToError) {
        this.handleScrollToError(error);
      }
      return;
    }
    // 初始化錯誤訊息
    let errMsg = this.ProcessErrMsg(error) || "發生未知錯誤";
    // 判斷錯誤是否要執行功能
    let errorFunction = null;

    // 如果有從後端回傳的數據，則進一步處理
    if (error.data) {
      const status = error.status;
      // 根據不同的狀態碼，進行相應的錯誤處理
      switch (status) {
        case 400:
          errMsg = error.data.Message || "請求錯誤";
          break;
        case 401:
          errMsg = "請登入後再試";
          errorFunction = () => {
            router.push({ name: "Logout" });
          };
          break;
        case 403:
          errMsg = "無權限訪問該頁面或功能";
          errorFunction = () => {
            router.go(-1);
          };
          break;
        case 404:
          errMsg = "查無此頁面或資料";
          break;
        case 500:
          errMsg = "發生未知的錯誤，請刷新頁面後再試。";
          errorFunction = () => {
            router.push({ name: "500" });
          };
          break;
        default:
          errMsg = "發生未知的錯誤，請刷新頁面後再試。";
          errorFunction = () => {
            router.push({ name: "Logout" });
          };
          break;
      }
    }

    // 顯示錯誤訊息
    await Swal.fire({
      icon: "error",
      html: `<div style='font-size:22px; text-align: center; font-weight: bold;'>${errMsg}</div>`,
      confirmButtonText: "確定",
    }).then(() => {
      if (errorFunction != null) {
        errorFunction();
        return;
      }
      if (shouldScrollToError) {
        this.handleScrollToError(error);
      }
    });
  },
  Axios: async function (
    url,
    method,
    data,
    Then,
    Catch = null,
    shouldScrollToError = false,
    Headers = {}
  ) {
    const DefaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    try {
      const response = await axiosServices({
        method: method,
        url: `${process.env.VUE_APP_URL}/${url}`,
        data: data,
        headers: { ...DefaultHeaders, ...Headers },
      });
      Then(response);
    } catch (err) {
      const processedError = new CustomError(
        this.ProcessErrMsg(err),
        err.response?.data,
        err.response?.status
      );
      // 等待 Catch 執行完，才會走下一行
      await this.HandleError(processedError, Catch, shouldScrollToError);
    }
  },
  AuthCheck: function (pageID, successCallBack, failureCallBack) {
    ApiHelper.Axios(
      `api/Users/Authority/${pageID}`,
      "GET",
      null,
      (res) => {
        if (res.data.IsLogin) {
          if (successCallBack) successCallBack(res);
        } else {
          if (failureCallBack) failureCallBack();
        }
      },
      () => {
        if (failureCallBack) failureCallBack();
      }
    );
  },
  PageList: {
    學校帳號管理: 1,
    個人首頁: 2,
    新增個案: 4,
    個案列表: 5,
    個案輔導新增個案: 7,
    個案輔導個案列表: 8,
    個案資料查詢: 9,
    文件下載: 10,
    頁面權限維護: 12,
    帳號維護: 13,
    文件維護: 14,
    學校資料維護: 15,
    最新消息維護: 17,
    中心簡介維護: 18,
    服務項目維護_工作任務: 19,
    服務項目維護_服務對象: 20,
    服務項目維護_服務方式: 21,
    活動成果維護: 22,
    表單下載維護_申請必要資料: 23,
    表單下載維護_個案服務表單: 24,
    表單下載維護_其他服務表單: 25,
    表單下載維護_行政表單: 26,
    常見問題維護: 27,
    中心成員列表: 35,
    服務學校列表: 36,
    中心人員服務總表: 31,
    個人月報表: 32,
    收案資料統計表: 33,
    個案內部轉介: 28,
    庫存管理: 38,
    借閱管理: 39,
    媒材申請: 40,
    回覆單設定: 41,
    核銷資料: 45,
    核銷項目設定: 46,
    變更密碼: 42,
    回首頁: 43,
    登出系統: 44,
  },
  GetUser() {
    return sessionStorage.getItem("User")
      ? JSON.parse(sessionStorage.getItem("User"))
      : {
          Group_aid: 0,
          Man_checkReply: false,
          Man_leader: false,
          Plan_Group: "",
          Sign_level: 0,
          Sign_level_Name: "",
          SchoolCode: "",
          RoleID: 0,
          UserName: "",
          TitleName: "",
          SchoolName: "",
          Userid: "",
          CityName: "",
          Man_Writeoff: false,
          Aid: 0,
        };
  },
  RoleID: {
    系統管理者: 1,
    申請人: 2,
    學校組長: 3,
    學校主任: 4,
    學校校長: 5,
    中心人員: 6,
    中心組長: 7,
    中心助理: 8,
    中心主任: 9,
    中心兼任人員: 10,
  },
};

export default ApiHelper;
