<script setup>
import { defineProps, ref, defineExpose } from "vue";
import ApiHelper from "@/helpers/ApiHelper";
import Swal from "sweetalert2";
import VueDatePicker from "@vuepic/vue-datepicker";
import store from "../../../state/store";
import _cloneDeep from 'lodash/cloneDeep';
const SystemName = ref(ApiHelper.SystemName);
const props = defineProps({
  caseID: Number,
  casestatus: Number,
  PageID: Number,
});
const MentalData = ref({
  PageID: 0,
  Aid: 0,
  Case_Master_Aid: 0,
  初評時間與對象: {
    初評時間: "",
    初評學生: false,
    初評教師: false,
    初評家長: false,
    學生初評: [],
    教師諮詢: [],
    家長諮詢: [],
  },
  主訴問題: "",
  家系圖暨說明: "",
  個案概述: "",
  背景資料: "",
  學校生活: "",
  Checkapply_Status: 4,
  Checkapply_Memo: "",
  初評心理師: "",
  Case_Sno: "",
  Checkapply_Read: "",
});
const showList = ref(true);
const showAdd = ref(false);
const checkapplyApiData = function () {
  ApiHelper.Axios(
    `api/CaseCenter/GetCheckapply/${props.caseID}`,
    "GET",
    null,
    (res) => {
      //確保MentalData 和 Vuex state 是完全獨立的不然會互相影響因為我的物件有嵌套结构所以要用深拷贝
      MentalData.value = _cloneDeep(res.data);  // 深拷贝
      store.commit("centersubmit/set心諮入校", _cloneDeep(res.data)); // 深拷贝
    }
  );
};
const AddMental = function (Aid) {
  MentalData.value.Case_Master_Aid = props.caseID;
  MentalData.value.PageID = props.PageID;
  MentalData.value.Aid = Aid;
  ApiHelper.Axios(
    "api/CaseCenter/SetCheckapplyMental",
    "POST",
    MentalData.value,
    (res) => {
      Swal.fire({
        icon: "success",
        text: `${res.data}`,
      }).then(() => {
        store.dispatch("model/closeModal");
        checkapplyApiData();
      });
    }
  );
};
const AddTimeType = ref("");
const AddTimeModel = ref(false);
const openAddTime = function (type) {
  AddTimeType.value = type;
  AddTimeModel.value = true;
  SelectTime(type);
};
const startDp = ref();
const finishDp = ref();
const StartDate = ref("");
const StartTime = ref({ hours: 0, minutes: 0 });
const FinishDate = ref("");
const FinishTime = ref({ hours: 0, minutes: 0 });
const RangeTime = ref([]);
const MentalCheckApplyFileList = ref([]);
//新增時間
const AddStudentTime = function () {
  if (!StartDate.value || !FinishDate.value) {
    alert('開始日期和結束日期不能是空白');
    return;
  }

  const startDateTime = createDateObject(StartDate.value, StartTime.value);
  const finishDateTime = createDateObject(FinishDate.value, FinishTime.value);

  if (startDateTime >= finishDateTime) {
    alert('開始時間不可以小於或等於結束時間');
    return;
  }
  switch (AddTimeType.value) {
    case "學生初評":
      MentalData.value.初評時間與對象.學生初評.push([
        startDateTime,
        finishDateTime,
      ]);
      break;
    case "教師諮詢":
      MentalData.value.初評時間與對象.教師諮詢.push([
        startDateTime,
        finishDateTime,
      ]);
      break;
    case "家長諮詢":
      MentalData.value.初評時間與對象.家長諮詢.push([
        startDateTime,
        finishDateTime,
      ]);
      break;
  }
  totaltime();
  StartDate.value = "";
  StartTime.value = { hours: 0, minutes: 0 };
  FinishDate.value = "";
  FinishTime.value = { hours: 0, minutes: 0 };
  SelectTime(AddTimeType.value);
};
//移除時間
const removeTime = function (index) {
  RangeTime.value.splice(index, 1);
  switch (AddTimeType.value) {
    case "學生初評":
      MentalData.value.初評時間與對象.學生初評.splice(index, 1);
      break;
    case "教師諮詢":
      MentalData.value.初評時間與對象.教師諮詢.splice(index, 1);
      break;
    case "家長諮詢":
      MentalData.value.初評時間與對象.家長諮詢.splice(index, 1);
      break;
  }
  totaltime();
};
//計算個別時間的總時數
const calculateTotalHours = function (timePairs) {
  let totalMinutes = 0;
  for (let i = 0; i < timePairs.length; i++) {
    const startDate = new Date(timePairs[i][0]);
    const endDate = new Date(timePairs[i][1]);
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
    totalMinutes += diffInMinutes;
  }
  const totalHours = Math.round(totalMinutes / 6) / 10;
  return totalHours;
};
//加總初評時間
const totaltime = function () {
  let totalHours = 0;
  if (MentalData.value.初評時間與對象.學生初評.length > 0) {
    const studentInitialAssessment = MentalData.value.初評時間與對象.學生初評;
    totalHours += calculateTotalHours(studentInitialAssessment);
  }
  if (MentalData.value.初評時間與對象.教師諮詢.length > 0) {
    const teacherConsultation = MentalData.value.初評時間與對象.教師諮詢;
    totalHours += calculateTotalHours(teacherConsultation);
  }
  if (MentalData.value.初評時間與對象.家長諮詢.length > 0) {
    const parentConsultation = MentalData.value.初評時間與對象.家長諮詢;
    totalHours += calculateTotalHours(parentConsultation);
  }
  MentalData.value.初評時間與對象.初評時間 = totalHours;
};
const SelectTime = function (type) {
  switch (type) {
    case "學生初評":
      RangeTime.value = formatDates(MentalData.value.初評時間與對象.學生初評);
      break;
    case "教師諮詢":
      RangeTime.value = formatDates(MentalData.value.初評時間與對象.教師諮詢);
      break;
    case "家長諮詢":
      RangeTime.value = formatDates(MentalData.value.初評時間與對象.家長諮詢);
      break;
  }
};
const formatDates = function (datesArray) {
  return datesArray.map((dates) => {
    return dates
      .map((date_str) => {
        if (typeof date_str === "string") {
          let date_obj = date_str.split("T");
          return date_obj[0] + " " + date_obj[1].split(".")[0];
        } else if (date_str instanceof Date) {
          return date_str.toISOString().split(".")[0].replace("T", " ");
        }
      })
      .join(" ~ ");
  });
};
const createDateObject = function (dateValue, timeValue) {
  const dateParts = dateValue.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[2]);
  const hours = timeValue.hours;
  const minutes = timeValue.minutes;
  return new Date(Date.UTC(year, month, day, hours, minutes));
};
const ApplyMentalFileUpload = function () {
  let ipt = document.createElement("input");
  ipt.type = "file";
  ipt.multiple = true;
  ipt.accept = ".jpg,.jpeg,.png,.pdf";
  ipt.onchange = function () {
    let formData = new FormData();
    for (let i = 0; i < ipt.files.length; i++) {
      formData.append("Files", ipt.files[i]);
    }
    formData.append("Case_Master_aid", props.caseID);
    ApiHelper.Axios(
      "api/File/ApplyMentalFileUpload",
      "POST",
      formData,
      (res) => {
        GetMentalCheckApplyFileList();
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        });
      },
      null,
      false,
      {
        "Content-Type": "multipart/form-data", // 自定義的 Headers
      }
    );
  };
  ipt.click();
};
const GetMentalCheckApplyFileList = function () {
  ApiHelper.Axios(
    `api/CaseCenter/GetMentalCheckApplyFileList/${props.caseID}`,
    "GET",
    null,
    (res) => {
      MentalCheckApplyFileList.value = res.data;
    }
  );
};
const DeleteMentalFileUpload = function (FileAid) {
  ApiHelper.Axios(
    "api/File/DeleteCheckApplyFile/",
    "POST",
    [FileAid],
    (res) => {
      Swal.fire(`${res.data}`);
      GetMentalCheckApplyFileList();
    }
  );
};
const MentalCheckApplyFileDownload = function (aid) {
  return `${process.env.VUE_APP_URL}/api/File/MentalCheckApplyFileDownload/${aid}`;
}
//清除時間，將他設為00:00
const starttimereset = () => {
  StartTime.value = { hours: 0, minutes: 0 };
};
//清除時間，將他設為00:00
const finishtimereset = () => {
  FinishTime.value = { hours: 0, minutes: 0 };
};
//選擇日期
const selectDate = (whichDp) => {
  if (whichDp === "startDp") {
    startDp.value.selectDate();
  } else if (whichDp === "finishDp") {
    finishDp.value.selectDate();
  }
};
//清除日期
const clearValue = (whichDp) => {
  if (whichDp === "startDp") {
    startDp.value.clearValue();
  } else if (whichDp === "finishDp") {
    finishDp.value.clearValue();
  }
};

//查看按鈕
const ShowFormList = function () {
  showAdd.value = false;
  showList.value = true;

};
//新增按鈕
const ShowAddForm = function () {
  showAdd.value = true;
  showList.value = false;

};
//查看內容
const CheckFormList = function () {
  // for (let i = 0; i < MentalRecordPartialList.value.length; i++) {
  //   const currentRecord = MentalRecordPartialList.value[i];
  //   if (currentRecord.Aid === RecordID) {
  //     currentRecord.Open = !currentRecord.Open || false;

  //     // 找出與 Aid 匹配的記錄
  //     const matchingRecord = MentalRecordList.value.find(
  //       (record) => record.Aid === RecordID
  //     );

  //     if (matchingRecord) {
  //       // 將 StartTime 和 EndTime 從 "HH:mm" 格式轉換為 { hours, minutes } 對象
  //       const [startHours, startMinutes] =
  //         matchingRecord.StartTime.split(":").map(Number);
  //       const [endHours, endMinutes] =
  //         matchingRecord.EndTime.split(":").map(Number);

  //       // 更新 MentalConsultationRecord 的值
  //       MentalConsultationRecord.value = {
  //         ...matchingRecord,
  //         StartTime: { hours: startHours, minutes: startMinutes },
  //         EndTime: { hours: endHours, minutes: endMinutes },
  //       };
  //     }
  //   } else {
  //     currentRecord.Open = false;
  //   }
  // }
};

defineExpose({
  checkapplyApiData,
  GetMentalCheckApplyFileList,
});
</script>
<template>
  <div class="container">
    <div class="row align-items-center justify-content-center mb-3">
      <div class="col-12 text-center">
        <div class="display-5">
          <b>{{ SystemName }}-<br />心理師入校初評紀錄表</b>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="d-flex align-items-center justify-content-center gap-4">
        <div class="text-center">
          <label class="h4 mb-1">案號</label>
          <div>
            <input type="text" class="form-control d-inline-block text-center" :value="MentalData.Case_Sno" disabled />
          </div>
        </div>
        <div class="text-center">
          <label class="h4 mb-1">初評心理師</label>
          <div>
            <input type="text" class="form-control d-inline-block text-center" :value="MentalData.初評心理師" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增及查看功能 -->
  <div class=" mt-4 mb-4 d-flex align-items-center justify-content-center">
    <!-- 新增 -->
    <button type="button" class="btn btn-outline-primary btn-lg" @click="ShowAddForm">
      新增
    </button>
    <!-- 查看 -->
    <button type="button" class="btn btn-outline-primary btn-lg ms-4" @click="ShowFormList">
      查看
    </button>
  </div>
  <!-- 新增 -->
  <div v-if="showAdd">
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="mb-3"><mark>初評對象與時間</mark></h4>
        <!-- 學生初評 -->
        <div class="form-group mb-3 d-flex align-items-center">
          <div class="form-check me-3">
            <input style="width: 18px; height: 18px" class="form-check-input mt-2" type="checkbox" id="學生初評1"
              v-model="MentalData.初評時間與對象.初評學生" />
            <label class="form-check-label fs-3" for="學生初評1">學生初評</label>
          </div>
          <button v-if="MentalData.初評時間與對象.初評學生" type="button" class="btn btn-primary btn-md"
            @click="openAddTime('學生初評')">
            填寫時間
          </button>
        </div>
        <div class="form-group mb-3 d-flex align-items-center">
          <div class="form-check me-3">
            <input style="width: 18px; height: 18px" class="form-check-input mt-2" type="checkbox" id="學生初評2"
              v-model="MentalData.初評時間與對象.初評教師" />
            <label class="form-check-label fs-3" for="學生初評2">教師諮詢</label>
          </div>
          <button v-if="MentalData.初評時間與對象.初評教師" type="button" class="btn btn-primary" @click="openAddTime('教師諮詢')">
            填寫時間
          </button>
        </div>
        <div class="form-group mb-3 d-flex align-items-center">
          <div class="form-check me-3">
            <input style="width: 18px; height: 18px" class="form-check-input mt-2" type="checkbox" id="學生初評3"
              v-model="MentalData.初評時間與對象.初評家長" />
            <label class="form-check-label fs-3" for="學生初評3">家長諮詢</label>
          </div>
          <button v-if="MentalData.初評時間與對象.初評家長" type="button" class="btn btn-primary" @click="openAddTime('家長諮詢')">
            填寫時間
          </button>
        </div>
        <div>
          <label class="form-check-label fs-4">
            初評時間共&emsp;
            <input type="text" min="0" style="width: 60px" v-model="MentalData.初評時間與對象.初評時間" disabled />&emsp;小時
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="mb-3"><mark>心理困擾﹑議題等</mark></h4>
        <div class="input-group mb-3">
          <span class="input-group-text">主訴問題</span>
          <textarea id="主訴問題" class="form-control" rows="3" v-model="MentalData.主訴問題"></textarea>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <h3 class="flex-grow-1"><mark>家系/生態圖</mark></h3>
      <button type="button" class="btn btn-outline-primary btn-md" @click="ApplyMentalFileUpload()">
        上傳附檔
      </button>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="input-group mb-3">
          <span class="input-group-text fs-5">家系/生態圖</span>
          <textarea id="家系圖暨說明" class="form-control" wrap="soft" rows="3" v-model="MentalData.家系圖暨說明"
            maxlength="1000"></textarea>
        </div>
      </div>
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap align-middle">
            <thead>
              <tr>
                <th class="fs-5">檔案名稱</th>
                <th class="fs-5">上傳</th>
                <th class="fs-5">刪除</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mfile in MentalCheckApplyFileList" :key="mfile.Aid">
                <td class="fs-5"><a target="_blank" :href="MentalCheckApplyFileDownload(mfile.Aid)">{{
                  mfile.Original_Filename }}</a></td>
                <td class="fs-5">{{ mfile.Created_Datetime.split("T")[0] }}</td>
                <td class="fs-5">
                  <button class="btn btn-danger" @click="DeleteMentalFileUpload(mfile.Aid)">
                    刪除
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="mb-3">
          <mark>身心狀況、情緒行為特徵、學習適應、優勢能力</mark>
        </h4>
        <div class="input-group mb-3">
          <span class="input-group-text fs-5">個案概述</span>
          <textarea id="個案概述" class="form-control" rows="3" v-model="MentalData.個案概述"></textarea>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="mb-3">
          <mark>家庭互動概況、成長史、醫療史、內外在支持系統等</mark>
        </h4>
        <div class="input-group mb-3">
          <span class="input-group-text fs-5">背景資料</span>
          <textarea id="背景資料" class="form-control" rows="3" v-model="MentalData.背景資料"></textarea>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h4 class="mb-3">
          <mark>初級與次級輔導狀況、課業、人際、師生互動、校園重大事件等適應狀況</mark>
        </h4>
        <div class="input-group mb-3">
          <span class="input-group-text fs-5">學校生活</span>
          <textarea id="學校生活" class="form-control" rows="3" v-model="MentalData.學校生活"></textarea>
        </div>
      </div>
    </div>
    <div class="row mb-3" v-if="props.casestatus !== 211">
      <div class="col-sm-12 mb-3">
        <div class="mb-3" id="Checkapply_Status">
          <h3 class="mb-3"><mark>評估結果</mark></h3>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="radio-size" id="三級" value="3"
              v-model="MentalData.Checkapply_Status" />
            <label class="form-check-label fs-4" for="三級">建議三級諮商派案</label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="radio-size" id="二級" value="2"
              v-model="MentalData.Checkapply_Status" />
            <label class="form-check-label fs-4" for="二級">建議二級持續輔導</label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="radio-size" id="初級" value="1"
              v-model="MentalData.Checkapply_Status" />
            <label class="form-check-label fs-4" for="初級">建議初級持續輔導</label>
          </div>
        </div>
        <div class="mb-3">
          <h3 class="mb-3"><mark>評估建議</mark></h3>
          <div class="alert alert-danger fs-5">
            請點選主要建議，提供學校初級、二級可介入的部分，及三級派案建議。
          </div>
          <textarea id="Checkapply_Memo" class="form-control mt-2" aria-label="With textarea" wrap="soft" rows="4"
            v-model="MentalData.Checkapply_Memo" maxlength="1000"></textarea>
        </div>
      </div>
    </div>
    <div class="mt-4 d-flex align-items-center justify-content-center"
      v-if="props.casestatus == 211 || props.casestatus == 306">
      <button type="button" class="btn btn-outline-primary btn-lg" @click="AddMental(MentalData.Aid)">
        存檔
      </button>
    </div>
  </div>
  <!-- 查看 -->
  <div v-if="showList">
    <!-- v-for="Record in MentalRecordPartialList" :key="Record.Aid" -->
    <div>
      <table class="table align-middle dt-responsive nowrap w-100 table-check text-center">
        <thead>
          <tr>
            <th scope="col">初評次數</th>
            <th scope="col">初評對象</th>
            <th scope="col">初評時間</th>
            <th scope="col">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>學生初評</td>
            <td>3小時
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <ul class="list-unstyled hstack">
                  <!-- 查看 -->
                  <li>
                    <a class="btn btn-sm btn-soft-info" @click="CheckFormList(Record.Aid)">
                      <i class="mdi mdi-eye-outline fa-2x"></i>
                    </a>
                  </li>
                  <!-- 刪除 -->
                  <li>
                    <a class="btn btn-sm btn-soft-danger" @click="DelMentalConsultationRecord(Record.Aid)">
                      <i class="mdi mdi-delete-outline fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <!-- 顯示查看內容 -->
          <!-- <tr v-if="Record.Open">
            <td colspan="9999">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="mb-3"><mark>初評對象與時間</mark></h4>

                  <div class="form-group mb-3 d-flex align-items-center">
                    <div class="form-check me-3">
                      <input style="width: 18px; height: 18px" class="form-check-input mt-2" type="checkbox" id="學生初評1"
                        v-model="MentalData.初評時間與對象.初評學生" />
                      <label class="form-check-label fs-3" for="學生初評1">學生初評</label>
                    </div>
                    <button v-if="MentalData.初評時間與對象.初評學生" type="button" class="btn btn-primary btn-md"
                      @click="openAddTime('學生初評')">
                      填寫時間
                    </button>
                  </div>
                  <div class="form-group mb-3 d-flex align-items-center">
                    <div class="form-check me-3">
                      <input style="width: 18px; height: 18px" class="form-check-input mt-2" type="checkbox" id="學生初評2"
                        v-model="MentalData.初評時間與對象.初評教師" />
                      <label class="form-check-label fs-3" for="學生初評2">教師諮詢</label>
                    </div>
                    <button v-if="MentalData.初評時間與對象.初評教師" type="button" class="btn btn-primary"
                      @click="openAddTime('教師諮詢')">
                      填寫時間
                    </button>
                  </div>
                  <div class="form-group mb-3 d-flex align-items-center">
                    <div class="form-check me-3">
                      <input style="width: 18px; height: 18px" class="form-check-input mt-2" type="checkbox" id="學生初評3"
                        v-model="MentalData.初評時間與對象.初評家長" />
                      <label class="form-check-label fs-3" for="學生初評3">家長諮詢</label>
                    </div>
                    <button v-if="MentalData.初評時間與對象.初評家長" type="button" class="btn btn-primary"
                      @click="openAddTime('家長諮詢')">
                      填寫時間
                    </button>
                  </div>
                  <div>
                    <label class="form-check-label fs-4">
                      初評時間共&emsp;
                      <input type="text" min="0" style="width: 60px" v-model="MentalData.初評時間與對象.初評時間"
                        disabled />&emsp;小時
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="mb-3"><mark>心理困擾﹑議題等</mark></h4>
                  <div class="input-group mb-3">
                    <span class="input-group-text">主訴問題</span>
                    <textarea id="主訴問題" class="form-control" rows="3" v-model="MentalData.主訴問題"></textarea>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <h3 class="flex-grow-1"><mark>家系/生態圖</mark></h3>
                <button type="button" class="btn btn-outline-primary btn-md" @click="ApplyMentalFileUpload()">
                  上傳附檔
                </button>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <span class="input-group-text fs-5">家系/生態圖</span>
                    <textarea id="家系圖暨說明" class="form-control" wrap="soft" rows="3" v-model="MentalData.家系圖暨說明"
                      maxlength="1000"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-centered table-nowrap align-middle">
                      <thead>
                        <tr>
                          <th class="fs-5">檔案名稱</th>
                          <th class="fs-5">上傳</th>
                          <th class="fs-5">刪除</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="mfile in MentalCheckApplyFileList" :key="mfile.Aid">
                          <td class="fs-5"><a target="_blank" :href="MentalCheckApplyFileDownload(mfile.Aid)">{{
                            mfile.Original_Filename }}</a></td>
                          <td class="fs-5">{{ mfile.Created_Datetime.split("T")[0] }}</td>
                          <td class="fs-5">
                            <button class="btn btn-danger" @click="DeleteMentalFileUpload(mfile.Aid)">
                              刪除
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="mb-3">
                    <mark>身心狀況、情緒行為特徵、學習適應、優勢能力</mark>
                  </h4>
                  <div class="input-group mb-3">
                    <span class="input-group-text fs-5">個案概述</span>
                    <textarea id="個案概述" class="form-control" rows="3" v-model="MentalData.個案概述"></textarea>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="mb-3">
                    <mark>家庭互動概況、成長史、醫療史、內外在支持系統等</mark>
                  </h4>
                  <div class="input-group mb-3">
                    <span class="input-group-text fs-5">背景資料</span>
                    <textarea id="背景資料" class="form-control" rows="3" v-model="MentalData.背景資料"></textarea>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="mb-3">
                    <mark>初級與次級輔導狀況、課業、人際、師生互動、校園重大事件等適應狀況</mark>
                  </h4>
                  <div class="input-group mb-3">
                    <span class="input-group-text fs-5">學校生活</span>
                    <textarea id="學校生活" class="form-control" rows="3" v-model="MentalData.學校生活"></textarea>
                  </div>
                </div>
              </div>
              <div class="row mb-3" v-if="props.casestatus !== 211">
                <div class="col-sm-12 mb-3">
                  <div class="mb-3" id="Checkapply_Status">
                    <h3 class="mb-3"><mark>評估結果</mark></h3>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" name="radio-size" id="三級" value="3"
                        v-model="MentalData.Checkapply_Status" />
                      <label class="form-check-label fs-4" for="三級">建議三級諮商派案</label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" name="radio-size" id="二級" value="2"
                        v-model="MentalData.Checkapply_Status" />
                      <label class="form-check-label fs-4" for="二級">建議二級持續輔導</label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" name="radio-size" id="初級" value="1"
                        v-model="MentalData.Checkapply_Status" />
                      <label class="form-check-label fs-4" for="初級">建議初級持續輔導</label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <h3 class="mb-3"><mark>評估建議</mark></h3>
                    <div class="alert alert-danger fs-5">
                      請點選主要建議，提供學校初級、二級可介入的部分，及三級派案建議。
                    </div>
                    <textarea id="Checkapply_Memo" class="form-control mt-2" aria-label="With textarea" wrap="soft"
                      rows="4" v-model="MentalData.Checkapply_Memo" maxlength="1000"></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-4 d-flex align-items-center justify-content-center"
                v-if="props.casestatus == 211 || props.casestatus == 306">
                <button type="button" class="btn btn-outline-primary btn-lg" @click="AddMental(MentalData.Aid)">
                  存檔
                </button>
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
  <b-modal v-model="AddTimeModel" title="新增初評時間" size="md" ok-only no-close-on-backdrop ok-title="關閉" @ok="totaltime()">
    <div class="p-4">
      <h4 class="text-center mb-4">{{ AddTimeType }}</h4>

      <!-- 開始日期和時間 -->
      <div class="row mb-3">
        <div class="col-4 text-end">
          <label class="fs-4 align-middle">開始日期</label>
        </div>
        <div class="col-8">
          <b-form-input id="startDate" type="date" v-model="StartDate"></b-form-input>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4 text-end">
          <label class="fs-4 align-middle">開始時間</label>
        </div>
        <div class="col-8">
          <VueDatePicker @cleared="starttimereset" v-model="StartTime" time-picker minutes-increment="10"
            minutes-grid-increment="10" ref="startDp">
            <template #action-buttons>
              <div class="action-buttons">
                <button class="btn btn-outline-secondary custom-button" @click="clearValue('startDp')">
                  取消
                </button>
                <button class="btn btn-outline-primary custom-button" @click="selectDate('startDp')">
                  確定
                </button>
              </div>
            </template>
          </VueDatePicker>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4 text-end">
          <label class="fs-4 align-middle">結束日期</label>
        </div>
        <div class="col-8">
          <b-form-input id="finishDate" type="date" v-model="FinishDate"></b-form-input>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4 text-end">
          <label class="fs-4 align-middle">結束時間</label>
        </div>
        <div class="col-8">
          <VueDatePicker @cleared="finishtimereset" v-model="FinishTime" time-picker minutes-increment="10"
            minutes-grid-increment="10" ref="finishDp">
            <template #action-buttons>
              <div class="action-buttons">
                <button class="btn btn-outline-secondary custom-button" @click="clearValue('finishDp')">
                  取消
                </button>
                <button class="btn btn-outline-primary custom-button" @click="selectDate('finishDp')">
                  確定
                </button>
              </div>
            </template>
          </VueDatePicker>
        </div>
      </div>
      <!-- 新增按鈕 -->
      <div class="text-center mb-3">
        <button @click="AddStudentTime($event)" class="btn btn-primary">
          新增
        </button>
      </div>

      <div style="max-height: 300px; overflow-y: auto">
        <ul class="list-group">
          <li v-for="(Time, index) in RangeTime" :key="index"
            class="list-group-item d-flex justify-content-between align-items-center">
            {{ Time }}
            <button @click="removeTime(index)" class="btn btn-outline-danger btn-sm">
              移除
            </button>
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<style>
.action-buttons {
  display: flex;
  justify-content: space-between;
}

.custom-button {
  min-width: 80px;
  /* 設置最小寬度 */
  margin: 5px;
}
</style>
