<script setup>
import { defineProps, ref, defineExpose, watch } from "vue";
import ApiHelper from "@/helpers/ApiHelper";
import Swal from "sweetalert2";
import Enumerable from "linq";
import store from "../../../state/store";
import { useRoute } from "vue-router";
import _cloneDeep from "lodash/cloneDeep";
const SystemName = ref(ApiHelper.SystemName);
const props = defineProps({
  //從原始資料取得的個案ID，這樣才能取得該個案的併案資料
  caseID: Number,
  casestatus: Number,
});
const route = useRoute();
const PageID = route.meta.PageID;
const SocialDataList = ref([]);
//目前tabs的個案ID
const CaseID = ref(0);
//目前tabs的初評資料
const SocialData = ref({
  PageID: 0,
  Aid: 0,
  Case_Master_Aid: 0,
  接案次數: 0,
  接案日期: "",
  個案類型: "",
  其他個案類型: "",
  個案來源: "",
  學校資訊: "",
  社政資訊: "",
  警政資訊: "",
  民間資訊: "",
  其他資訊: "",
  家系圖暨說明: "",
  目前協助單位及內容: "",
  需求評估: "",
  Checkapply_Status: "",
  Checkapply_Memo: "",
  初評社工師: "",
  Case_Sno: "",
  Checkapply_Read: "",
  併案編號: "",
  CheckCaseSno: "",
});
// 初始化 CheckApplyFiles
const initCheckApplyFiles = () => {
  const items = [
    "中輟通報紀錄",
    "中輟復學紀錄",
    "校安通報紀錄",
    "個案研討會資料",
    "個案輔導紀錄",
    "其他",
  ];

  return items.map((item, index) => ({
    Aid: index + 1,
    序號: index + 1,
    項目: item,
    Files: [],
  }));
};
// 初始化 CheckApplyFiles
const CheckApplyFiles = ref(initCheckApplyFiles());
const showFile = ref(false);
const ShowSettingID = ref(0);
const imageUrl = ref("");
const currentTab = ref(0);
//取得
const checkapplyApiData = async function () {
  await ApiHelper.Axios(
    `api/CaseCenter/GetCheckapplySocialList/${props.caseID}`,
    "GET",
    null,
    (response) => {
      const formattedData = formatData(response.data);
      SocialDataList.value = formattedData;
      findAndUpdateData(SocialDataList.value);
    }
  );
  watch(
    () => SocialData.value.併案編號,
    (val) => {
      if (val) {
        SocialData.value.CheckCaseSno = false;
      }
    }
  );
};
const formatData = (data) => {
  return data.map((item, index) => ({
    id: index,
    data: item,
  }));
};
const findAndUpdateData = (list) => {
  const item = list.find((item) => item.id === 0);
  if (!item) return;
  SocialData.value = _cloneDeep(item.data);
  store.commit("centersubmit/set社工入校", _cloneDeep(item.data));
  GetCheckApplyFileList(SocialData.value.Case_Master_Aid);
  updateImageUrl(SocialData.value.Case_Master_Aid);
  CaseID.value = SocialData.value.Case_Master_Aid;
};
const updateImageUrl = (id) => {
  imageUrl.value = `${process.env.VUE_APP_URL
    }/api/File/SocialCheckApplyFile7Download/${id}?t=${new Date().getTime()}`;
};
//新增
const AddSocial = async function (aid) {
  // 檢查併案條件
  if (
    SocialData.value.Checkapply_Status === "3" &&
    !SocialData.value.CheckCaseSno
  ) {
    Swal.fire({
      icon: "info",
      title: "請先確認要併案的個案編號是否存在!",
    });
    return;
  }
  // 更新 SocialData.value 的屬性
  Object.assign(SocialData.value, {
    Case_Master_Aid: CaseID.value,
    PageID: PageID,
    Aid: aid,
  });
  await ApiHelper.Axios(
    "api/CaseCenter/SetCheckapplySocial",
    "POST",
    SocialData.value,
    (response) => {
      // 處理成功回應
      Swal.fire({
        icon: "success",
        text: `${response.data}`,
      }).then(() => {
        store.dispatch("model/closeModal");
        checkapplyApiData();
      });
    },
  );
};
//上傳附件
const ApplyFileUpload = async function (Setting_ID) {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.multiple = true;
  fileInput.accept = ".jpg,.jpeg,.png,.pdf,.docx";
  fileInput.onchange = async function () {
    const formData = new FormData();
    Array.from(fileInput.files).forEach((file) =>
      formData.append("Files", file)
    );
    formData.append("Case_Master_aid", CaseID.value);
    formData.append("Setting_ID", Setting_ID);
    await ApiHelper.Axios(
      "api/File/CheckApplyFileUpload",
      "POST",
      formData,
      (res) => {
        GetCheckApplyFileList(CaseID.value);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        });
      },
      null,
      false,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  };
  fileInput.click();
};
// 上傳家系圖
const CheckApplyFile7Upload = async function () {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.multiple = false;
  fileInput.accept = ".jpg,.jpeg,.png";
  fileInput.onchange = async function () {
    const formData = new FormData();
    formData.append("Files", fileInput.files[0]);
    formData.append("Case_Master_aid", CaseID.value);
    await ApiHelper.Axios(
      `api/File/CheckApplyFile7Upload`,
      "POST",
      formData,
      (res) => {
        updateImageUrl(CaseID.value);
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
        });
      },
      null,
      false,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  };
  fileInput.click();
};
//取得附檔列表
const GetCheckApplyFileList = async function (aid) {
  await ApiHelper.Axios(
    `api/CaseCenter/GetCheckApplyFileList/${aid}`,
    "GET",
    null,
    (response) => {
      const groupedData = Enumerable.from(response.data)
        .where((x) => x.FileSetting_Aid !== 7)
        .groupBy((x) => x.FileSetting_Aid)
        .toArray();
      groupedData.forEach((group) => {
        const target = CheckApplyFiles.value.find(
          (item) => item.Aid === group.key()
        );
        if (target) {
          target.Files = group.getSource();
        }
      });
    }
  );
};
//刪除附件
const DelApplyFile = async function (FileAid) {
  await ApiHelper.Axios(
    "api/File/DeleteCheckApplyFile/",
    "POST",
    [FileAid],
    (res) => {
      Swal.fire(`${res.data}`);
      // 初始化 CheckApplyFiles
      CheckApplyFiles.value = initCheckApplyFiles();
      GetCheckApplyFileList(CaseID.value);
      GetApplyList(ShowSettingID.value);
    }
  );
};
//取得附件
const GetApplyList = function (Setting_ID) {
  ShowSettingID.value = Setting_ID;
  showFile.value = true;
};
const SocialCheckApplyFileDownload = function (aid) {
  return `${process.env.VUE_APP_URL}/api/File/SocialCheckApplyFileDownload/${aid}`;
};
const CheckMergeCaseSno = function () {
  if (SocialData.value.併案編號 == "") {
    Swal.fire({
      icon: "error",
      title: "請輸入併案編號",
    });
    return;
  }
  ApiHelper.Axios(
    `api/CaseCenter/CheckMergeCaseSno/${SocialData.value.併案編號}`,
    "GET",
    null,
    (res) => {
      SocialData.value.CheckCaseSno = res.data;
      const message = res.data ? "個案存在" : "個案不存在";
      Swal.fire({
        icon: "info",
        title: message,
      });
    }
  );
};
const changeTab = function (tabId) {
  // 使用 find 方法找到符合條件的項目
  const newSocialData = SocialDataList.value.find((item) => item.id === tabId);
  // 更新 SocialData 的值
  if (newSocialData) {
    SocialData.value = { ...newSocialData.data };
    GetCheckApplyFileList(SocialData.value.Case_Master_Aid);
    // 紀錄該初評的個案ID
    CaseID.value = SocialData.value.Case_Master_Aid;
    // 更新圖片 URL
    updateImageUrl(SocialData.value.Case_Master_Aid);
  }
  // 更新 currentTab 的值
  currentTab.value = tabId;
};
defineExpose({
  checkapplyApiData,
});
</script>
<template>
  <ul class="nav nav-tabs">
    <li class="nav-item" v-for="(tab, index) in SocialDataList" :key="index">
      <a class="nav-link" :class="{ active: currentTab === index }" @click="changeTab(index)">
        {{ tab.data.Case_Sno }}
      </a>
    </li>
  </ul>
  <div class="container mt-4">
    <div>
      <div class="row align-items-center justify-content-center mb-3">
        <div class="col-12 text-center">
          <div class="display-5">
            <b>{{ SystemName }}-<br />社工組評估表</b>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-3">
        <div class="d-flex align-items-center justify-content-center gap-4">
          <div class="text-center">
            <label class="h4 mb-1">案號</label>
            <div>
              <input type="text" class="form-control d-inline-block text-center" :value="SocialData.Case_Sno"
                disabled />
            </div>
          </div>
          <div class="text-center">
            <label class="h4 mb-1">初評社工師</label>
            <div>
              <input type="text" class="form-control d-inline-block text-center" :value="SocialData.初評社工師" disabled />
            </div>
          </div>
        </div>
      </div>
      <!-- 接案日期 -->
      <div class="row align-items-center mb-3">
        <div class="col-md-12 px-1 mb-2 fs-4">
          <label for="date">接案日期:</label>
          <b-form-input id="接案日期" type="date" v-model="SocialData.接案日期"></b-form-input>
        </div>
      </div>

      <!-- 接案次數 -->
      <div class="row align-items-center mb-3">
        <div class="col-md-12 px-1 mb-2 fs-4">
          <label for="caseTimes">接案次數:</label>
          <input type="number" id="接案次數" min="0" class="form-control" v-model="SocialData.接案次數" />
        </div>
      </div>

      <!-- 個案來源 -->
      <div class="row align-items-center mb-3">
        <div class="col-md-12 px-1 mb-2 fs-4">
          <label for="caseSource">個案來源:</label>
          <select class="form-select" v-model="SocialData.個案來源" id="個案來源">
            <option disabled value="">請選擇個案來源</option>
            <option value="校安通報">校安通報</option>
            <option value="家暴目睹知會單">家暴目睹知會單</option>
            <option value="中輟通報">中輟通報</option>
            <option value="學校申請">學校申請</option>
          </select>
        </div>
      </div>

      <!-- 個案類型 -->
      <div class="row align-items-center mb-3">
        <div class="col-md-12 px-1 mb-2 fs-4">
          <label for="caseType">個案類型:</label>
          <select class="form-select" v-model="SocialData.個案類型" id="個案類型">
            <option disabled value="">請選擇案件類型</option>
            <option value="T01.人際困擾">T01.人際困擾</option>
            <option value="T02.師生關係">T02.師生關係</option>
            <option value="T03.家庭困擾">T03.家庭困擾</option>
            <option value="T04.自我探索">T04.自我探索</option>
            <option value="T05.情緒困擾">T05.情緒困擾</option>
            <option value="T06.生活壓力">T06.生活壓力</option>
            <option value="T07.創傷反應">T07.創傷反應</option>
            <option value="T08.自我傷害">T08.自我傷害</option>
            <option value="T09.性別議題">T09.性別議題</option>
            <option value="T10.脆弱家庭">T10.脆弱家庭</option>
            <option value="T11.兒少保護議題">T11.兒少保護議題</option>
            <option value="T12.學習困擾">T12.學習困擾</option>
            <option value="T13.生涯輔導">T13.生涯輔導</option>
            <option value="T14.偏差行為">T14.偏差行為</option>
            <option value="T15.網路沉迷">T15.網路沉迷</option>
            <option value="T16.中離(輟)拒學">T16.中離(輟)拒學</option>
            <option value="T17.藥物濫用">T17.藥物濫用</option>
            <option value="T18.精神疾病">T18.精神疾病</option>
            <option value="T19.其他">T19.其他</option>
          </select>
        </div>
      </div>
      <!-- 其他個案類型 -->
      <div class="row align-items-center mb-3" v-if="SocialData.個案類型 == 'T19.其他'">
        <div class="col-md-12 px-1 mb-2 fs-4">
          <label for="newInput">其他個案類型:</label>
          <input type="text" id="其他個案類型" class="form-control" v-model="SocialData.其他個案類型" placeholder="請輸入其他個案類型" />
        </div>
      </div>
      <!-- 聯繫窗口 -->
      <div class="form-group mb-3 fs-4">
        <h3><mark>聯繫窗口</mark></h3>
        <div class="row align-items-center mt-3">
          <label for="schoolContact" class="col-md-3">學校（聯絡人/電話）</label>
          <div class="col-md-9">
            <textarea id="學校資訊" class="form-control" v-model="SocialData.學校資訊" rows="3" maxlength="1000"></textarea>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <label for="socialPolicyContact" class="col-md-3">社政（聯絡人/電話）</label>
          <div class="col-md-9">
            <textarea id="社政資訊" class="form-control" v-model="SocialData.社政資訊" rows="3" maxlength="1000"></textarea>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <label for="schoolContact" class="col-md-3">警政（聯絡人/電話）</label>
          <div class="col-md-9">
            <textarea id="警政資訊" class="form-control" v-model="SocialData.警政資訊" rows="3" maxlength="1000"></textarea>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <label for="schoolContact" class="col-md-3">民間單位（聯絡人/電話）</label>
          <div class="col-md-9">
            <textarea id="民間資訊" class="form-control" v-model="SocialData.民間資訊" rows="3" maxlength="1000"></textarea>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <label for="schoolContact" class="col-md-3">其他（聯絡人/電話）</label>
          <div class="col-md-9">
            <textarea id="其他資訊" class="form-control" v-model="SocialData.其他資訊" rows="3" maxlength="1000"></textarea>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <!-- 附件 -->
        <section>
          <h3><mark>附件資料</mark></h3>
          <div class="row mt-4 mb-3">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-striped align-middle">
                <thead>
                  <tr>
                    <th class="fs-5">上傳項目</th>
                    <th class="fs-5">已上傳項目</th>
                    <th class="fs-5">已上傳數量</th>
                    <th class="fs-5">上傳</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, index) in CheckApplyFiles" :key="index">
                    <td>{{ file.項目 }}</td>
                    <td>
                      <button :class="{
                        'btn btn-primary': true,
                        disabled: file.Files.length === 0,
                      }" @click="GetApplyList(file.Aid)">
                        查看上傳內容
                      </button>
                    </td>
                    <td>{{ file.Files.length }}</td>
                    <td>
                      <button class="btn btn-primary" @click="ApplyFileUpload(file.Aid)">
                        上傳
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <!-- 摘要 -->
        <section>
          <div class="d-flex align-items-center mb-3">
            <h3 class="flex-grow-1"><mark>事件摘要</mark></h3>
            <button type="button" class="btn btn-outline-primary btn-md" @click="CheckApplyFile7Upload()">
              上傳家系圖
            </button>
          </div>
          <div class="row mt-4 mb-2">
            <div class="input-group">
              <span class="input-group-text fs-5 text-nowrap">家系圖暨說明</span>
              <textarea class="form-control" id="家系圖暨說明" wrap="soft" rows="3" v-model="SocialData.家系圖暨說明"
                maxlength="1000"></textarea>
            </div>
          </div>
          <div class="row">
            <img :src="imageUrl" alt="圖片預覽" width="100" />
          </div>
          <div class="row mt-4 mb-4">
            <div class="input-group">
              <span class="input-group-text fs-5">目前協助單位及內容</span>
              <textarea class="form-control" id="目前協助單位及內容" rows="3" v-model="SocialData.目前協助單位及內容"
                maxlength="1000"></textarea>
            </div>
          </div>
        </section>
        <!-- 需求 -->
        <section>
          <h3><mark>需求評估</mark></h3>
          <div class="row mt-4 mb-4">
            <div class="input-group">
              <span class="input-group-text fs-5">需求評估</span>
              <textarea class="form-control" id="需求評估" rows="5" v-model="SocialData.需求評估" maxlength="1000"></textarea>
            </div>
          </div>
        </section>
        <!-- 建議 -->
        <section id="併案個案編號">
          <div class="d-flex align-items-center mb-3">
            <h3><mark>處遇建議</mark></h3>
            <!-- 文字說明 -->
            <div class="alert alert-info fs-5 ms-2" v-if="SocialData.Checkapply_Status == 3">
              請輸入案件編號並點擊「查詢」以確認是否存在。
            </div>
          </div>
          <div class="form-group mb-4">
            <div class="d-flex align-items-center">
              <div class="form-check me-4">
                <input class="form-check-input" type="radio" id="列案" v-model="SocialData.Checkapply_Status"
                  :value="1" />
                <label class="form-check-label fs-4" for="列案">列案</label>
              </div>
              <div class="form-check me-4">
                <input class="form-check-input" type="radio" id="不列案" v-model="SocialData.Checkapply_Status"
                  :value="0" />
                <label class="form-check-label fs-4" for="不列案">不列案</label>
              </div>
              <div class="form-check me-4">
                <input class="form-check-input" type="radio" id="暫不列案" v-model="SocialData.Checkapply_Status"
                  :value="2" />
                <label class="form-check-label fs-4" for="暫不列案">暫不列案</label>
              </div>
              <div class="form-check me-4">
                <input class="form-check-input" type="radio" id="併案" v-model="SocialData.Checkapply_Status"
                  :value="3" />
                <label class="form-check-label fs-4" for="併案">併案</label>
              </div>
              <div class="d-flex align-items-center" v-if="SocialData.Checkapply_Status == 3">
                <input type="text" id="併案編號" class="form-control me-2" placeholder="請輸入併案編號"
                  v-model="SocialData.併案編號" />
                <button class="btn btn-primary col-md-4" @click="CheckMergeCaseSno()">
                  查詢
                </button>
              </div>
            </div>
          </div>
        </section>
        <!-- 說明 -->
        <section>
          <h3><mark>建議原因</mark></h3>
          <div class="row mt-4 overflow-x-auto">
            <table class="table table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th class="fs-2" style="text-align: center; vertical-align: middle">
                    意見
                  </th>
                  <td class="text-center vertical-align-middle">
                    <textarea class="form-control" id="Checkapply_Memo" wrap="soft" rows="3"
                      v-model="SocialData.Checkapply_Memo" maxlength="1000"></textarea>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- 按鈕 -->
  <div class="d-flex align-items-center justify-content-center">
    <button type="button" v-if="props.casestatus == 211" class="btn btn-primary btn-lg ms-2"
      @click="AddSocial(SocialData.Aid)">
      存檔
    </button>
  </div>
  <b-modal v-model="showFile" title="請上傳附件檔案" size="md" ok-only ok-title="關閉" centered>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="mb-3">已上傳文件：</p>
        </div>
      </div>

      <div class="row" v-for="File in CheckApplyFiles" :key="'CheckApplyFiles' + File.Aid">
        <div class="col-md-12" v-if="File.Aid === ShowSettingID">
          <div class="d-flex justify-content-between align-items-center mb-2" v-for="data in File.Files"
            :key="'ApplyFile' + data.Aid">
            <a class="fs-6 text-primary" :href="SocialCheckApplyFileDownload(data.Aid)" target="_blank">
              {{ data.Original_Filename }}
            </a>
            <button type="button" class="btn btn-outline-danger btn-sm" @click="DelApplyFile(data.Aid)">
              <i class="bx bx-x"></i> 刪除
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
