import ApiHelper from "@/helpers/ApiHelper";
import Swal from "sweetalert2";

export const state = {
  caseData: {}, // 來自第一個組件的表單數據
  MemoData: "", // 來自第二個組件的意見數據
  SignUserData: {}, // 來自第二個組件的指定長官數據
  isDataReady: false, // 是否已經取得所有數據
  sloader: false,
};

export const mutations = {
  setCaseData(state, payload) {
    state.caseData = payload;
  },
  setMemoData(state, payload) {
    state.MemoData = payload;
  },
  setSignUserData(state, payload) {
    state.SignUserData = payload;
  },
  setDataReady(state, value) {
    state.isDataReady = value;
  },
  LOADER(state, sloader) {
    state.sloader = sloader;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

export const actions = {
  async SubmitCase({ state, commit }, PageID) {
    const result = await Swal.fire({
      title: "<strong>陳核確認</strong>",
      html: "送出後即<strong>無法修正內容</strong>，<br>若確認資料無誤，請點選確定陳核。<br><br><small>(如只需暫存請點擊上方存檔按鈕，以利後續繼續編輯)</small>",
      icon: "info",
      backdrop: "rgba(128, 128, 128, 0.4)",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#5a5a5a",
      confirmButtonText: "<b>確定陳核</b>",
      cancelButtonText: "返回修改",
      animation: "zoom-in",
    });
    if (result.isConfirmed) {
      commit("LOADER", true);
      const allData = {
        Case_Master_aid: state.caseData.Aid,
        Memo: state.MemoData,
        UserID: state.SignUserData.Aid,
        CaseForm: state.caseData,
        Sign: state.SignUserData.Sign,
        PageID: PageID,
      };
      await ApiHelper.Axios(
        "api/Case/SubmitCase",
        "POST",
        allData,
        (res) => {
          commit("LOADER", false);
          Swal.fire({
            icon: "success",
            title: `${res.data}`,
          }).then(() => {
            location.href = "/System";
          });
        },
        (err) => {
          commit("LOADER", false);
          Swal.fire({
            icon: "error",
            title: `${err}`,
          });
        },
        true
      );
    }
  },
  async Implement({ state, commit }, PageID) {
    const result = await Swal.fire({
      title: "<strong>決行確認</strong>",
      html: "請確認資料<strong>無誤</strong>，然後進行決行。",
      icon: "info",
      backdrop: "rgba(128, 128, 128, 0.4)",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#5a5a5a",
      confirmButtonText: "<b>確認無誤</b>",
      cancelButtonText: "返回修改",
      animation: "zoom-in",
    });
    if (result.isConfirmed) {
      commit("LOADER", true);
      // 組合來自不同組件的數據
      const allData = {
        Case_Master_aid: state.caseData.Aid,
        Memo: state.MemoData,
        UserID: state.SignUserData.Aid,
        CaseForm: state.caseData,
        PageID: PageID,
      };
      // 送出資料
      await ApiHelper.Axios(
        "api/Case/ImplementCase",
        "POST",
        allData,
        (res) => {
          commit("LOADER", false);
          Swal.fire({
            icon: "success",
            title: `${res.data}`,
          }).then(() => {
            location.href = "/System";
          });
        },
        (err) => {
          commit("LOADER", false);
          Swal.fire({
            icon: "error",
            title: `${err}`,
          });
        },
        false
      );
    }
  },
  async decideAction({ dispatch, state }) {
    const result = await Swal.fire({
      title: "您想要做什麼？",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "結案",
      denyButtonText: "結案後續案",
      cancelButtonText: "取消",
    });
    if (result.isConfirmed) {
      await dispatch("CloseCase", { CaseID: state.caseData.Aid, type: 1 });
    } else if (result.isDenied) {
      await dispatch("Continuecase", { CaseID: state.caseData.Aid, type: 2 });
    }
  },
  async Continuecase({ dispatch }, { CaseID, type }) {
    const result = await Swal.fire({
      title: "您確定要申請續案嗎?",
      showDenyButton: true,
      confirmButtonText: "是",
      denyButtonText: "否",
    });

    if (result.isConfirmed) {
      await dispatch("CloseCase", { CaseID, type });
    }
  },
  async CloseCase(_, { CaseID, type }) {
    await ApiHelper.Axios(
      `api/Case/CloseCase/${CaseID}/${type}`,
      "GET",
      null,
      (res) => {
        Swal.fire({
          icon: "info",
          text: res.data,
        }).then(() => {
          location.href = "/System";
        });
      }
    );
  },
};
