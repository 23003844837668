<script setup>
import { defineProps, ref, defineExpose, computed } from "vue";
import ApiHelper from "@/helpers/ApiHelper";
import Swal from "sweetalert2";
import store from "../../../state/store";


//定義屬性
const props = defineProps({
  caseID: Number,
  casestatus: Number,
  PageID: Number,
});
const SchoolList = computed(() => store.state.centersubmit.schoolList);
//定義物件
const Evaluation = ref({
  PageID: 0,
  Case_Master_Aid: 0,
  Case_Sno: "",
  UserName: "",
  Indo: "",
  Sex: 0,
  SchoolCode: "",
  SchoolName: "",
  Grade: "",
  Class: "",
  Birthday: "",
  School_Liaison: "",
  School_Phone: "",
  School_Mail: "",
  Parent_Name: "",
  Parent_Phone: "",
  Lowincome: 0,
  Family_relation: "",
  Lnterpersonal: "",
  Behavior: "",
  Study: "",
  Counseling_diff: "",
  Referral_Expect: "",
  家庭圖與家庭狀況: "",
  學習表現: "",
  情緒行為與其他: "",
  情感問題: 0,
  焦慮問題: 0,
  身體問題: 0,
  注意力缺陷過動問題: 0,
  對立反抗問題: 0,
  品行問題: 0,
  焦慮憂鬱: 0,
  退縮憂鬱: 0,
  身體抱怨: 0,
  社會問題: 0,
  思考問題: 0,
  注意力問題: 0,
  違反規範行為: 0,
  攻擊行為: 0,
  其他: "",
  評估結果: 0,
  補助次數: 0,
  間接補助次數: 0,
});
//取得
const fetchApiData = function () {
  ApiHelper.Axios(
    `api/CaseCenter/GetEvaluation/${props.caseID}`,
    "GET",
    null,
    (res) => {
      Evaluation.value = { ...Evaluation.value, ...res.data };
      store.commit("centersubmit/set中心評估", res.data);
    }
  );
};
//新增
const AddEvaluation = function () {
  Evaluation.value.PageID = props.PageID;
  ApiHelper.Axios(
    "api/CaseCenter/AddEvaluation",
    "POST",
    Evaluation.value,
    (res) => {
      Swal.fire({
        icon: "success",
        text: `${res.data}`,
      }).then(() => {
        store.dispatch("model/closeModal");
        fetchApiData();
      });
    }
  );
};
const AchenbackSelect = [
  { id: 1, name: "臨床範圍" },
  { id: 2, name: "邊緣臨床" },
  { id: 3, name: "正常範圍" },
];
const Achenback = [
  "情感問題",
  "焦慮問題",
  "身體問題",
  "注意力缺陷過動問題",
  "對立反抗問題",
  "品行問題",
];
const Achenback1 = [
  "焦慮憂鬱",
  "退縮憂鬱",
  "身體抱怨",
  "社會問題",
  "思考問題",
  "注意力問題",
  "違反規範行為",
  "攻擊行為",
];
const getBackgroundColor = function (value) {
  if (value === 3) {
    return "rgba(0, 128, 0, 0.2)";
  } else if (value === 1) {
    return "rgba(255, 0, 0, 0.2)";
  } else if (value === 2) {
    return "rgba(255, 255, 0, 0.2)";
  } else {
    return "transparent";
  }
};
// 使用 defineExpose 公开 fetchApiData 函数
defineExpose({
  fetchApiData,
});
</script>
<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="container-fluid mb-3">
          <div class="row justify-content-end align-items-center">
            <div class="col-md-8 col-12 text-center">
              <h1 class="display-5">
                <b>【中心初評表】</b>
              </h1>
            </div>
            <div class="col-md-2 col-12 d-flex justify-content-between align-items-end">
              <div>
                <h4>案號:{{ Evaluation.Case_Sno }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid fs-4">
          <form>
            <div class="row">
              <div class="col-md-2 col-12 mb-3">
                <label for="學生姓名" class="form-label">學生姓名</label>
                <input disabled type="text" class="form-control" maxlength="10" id="學生姓名"
                  v-model="Evaluation.UserName" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="身分證" class="form-label">身分證字號</label>
                <input disabled type="text" class="form-control" placeholder="非本國籍輸入居留證號碼" maxlength="10" id="身分證"
                  v-model="Evaluation.Indo" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="性別" class="form-label">性別</label>
                <select class="form-select" id="性別" v-model="Evaluation.Sex" disabled>
                  <option value="0">請選擇</option>
                  <option value="1">男</option>
                  <option value="2">女</option>
                </select>
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="學校" class="form-label">學校</label>
                <select class="form-select" id="學校" v-model="Evaluation.SchoolCode" disabled>
                  <option :value="null" selected hidden>請選擇學校</option>
                  <option v-for="school in SchoolList" :key="school.SchoolCode" :value="school.SchoolCode">
                    {{ school.SchoolName }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="年/班級" class="form-label d-block">年/班級</label>
                <div class="d-flex justify-content-between">
                  <select disabled class="form-select me-2" id="年/班級" style="width: 49%" v-model="Evaluation.Grade">
                    <option value="0">請選擇年級</option>
                    <option value="一年級">一年級</option>
                    <option value="二年級">二年級</option>
                    <option value="三年級">三年級</option>
                    <option value="四年級">四年級</option>
                    <option value="五年級">五年級</option>
                    <option value="六年級">六年級</option>
                    <option value="七年級">七年級</option>
                    <option value="八年級">八年級</option>
                    <option value="九年級">九年級</option>
                    <option value="高一">高一</option>
                    <option value="高二">高二</option>
                    <option value="高三">高三</option>
                  </select>
                  <input disabled type="text" class="form-control" id="年/班級" style="width: 49%"
                    v-model="Evaluation.Class" />
                </div>
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="生日" class="form-label">生日</label>
                <input disabled type="date" id="生日" class="form-control" min="2001-01-01"
                  v-model="Evaluation.Birthday" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="學校聯絡人" class="form-label">學校聯絡人</label>
                <input disabled type="text" class="form-control" maxlength="10" id="學校聯絡人"
                  v-model="Evaluation.School_Liaison" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="學校聯絡電話" class="form-label">學校聯絡電話</label>
                <input disabled type="text" class="form-control" maxlength="10" id="學校聯絡電話"
                  v-model="Evaluation.School_Phone" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="聯絡人電子信箱" class="form-label">聯絡人電子信箱</label>
                <input disabled type="text" class="form-control" id="聯絡人電子信箱" v-model="Evaluation.School_Mail" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="家長姓名" class="form-label">家長姓名</label>
                <input disabled type="text" class="form-control" maxlength="10" id="家長姓名"
                  v-model="Evaluation.Parent_Name" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="家長電話" class="form-label">家長電話</label>
                <input disabled type="text" class="form-control" maxlength="10" id="家長電話"
                  v-model="Evaluation.Parent_Phone" />
              </div>
              <div class="col-md-2 col-12 mb-3">
                <label for="Lowincome" class="form-label">中低收入戶</label>
                <select disabled class="form-select" v-model="Evaluation.Lowincome" id="Lowincome">
                  <option value="0">請選擇</option>
                  <option value="1">是</option>
                  <option value="2">否</option>
                </select>
              </div>
              <div class="col-12 mb-3">
                <div class="input-group">
                  <span class="input-group-text fs-5">主述<br />(學校簡述)</span>
                  <div class="container-fluid form-control d-flex flex-column text-center p-2 fs-sm">
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-2">
                        <label class="form-label fs-3">家庭關係</label>
                      </div>
                      <div class="col-12 col-md-10">
                        <textarea disabled class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.Family_relation"></textarea>
                      </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-2">
                        <label class="form-label fs-3">人際關係</label>
                      </div>
                      <div class="col-12 col-md-10">
                        <textarea disabled class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.Lnterpersonal"></textarea>
                      </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-2">
                        <label class="form-label fs-3">行為問題</label>
                      </div>
                      <div class="col-12 col-md-10">
                        <textarea disabled class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.Behavior"></textarea>
                      </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-2">
                        <label class="form-label fs-3">學習表現</label>
                      </div>
                      <div class="col-12 col-md-10">
                        <textarea disabled class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.Study"></textarea>
                      </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-2">
                        <label class="form-label fs-3">輔導困境</label>
                      </div>
                      <div class="col-12 col-md-10">
                        <textarea disabled class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.Counseling_diff"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="input-group">
                  <span class="input-group-text fs-4">背景資料</span>
                  <div class="container-fluid form-control d-flex flex-column text-center p-2 fs-sm">
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-3">
                        <label class="form-label fs-3">家庭圖與家庭狀況</label>
                      </div>
                      <div class="col-12 col-md-9">
                        <textarea id="家庭圖與家庭狀況" class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.家庭圖與家庭狀況"></textarea>
                      </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-3">
                        <label class="form-label fs-3">學習表現</label>
                      </div>
                      <div class="col-12 col-md-9">
                        <textarea id="學習表現" class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.學習表現"></textarea>
                      </div>
                    </div>
                    <div class="row mb-2 d-flex align-items-center">
                      <div class="col-12 col-md-3">
                        <label class="form-label fs-3">情緒、行為與其他</label>
                      </div>
                      <div class="col-12 col-md-9">
                        <textarea id="情緒行為與其他" class="form-control" rows="4" maxlength="1000"
                          v-model="Evaluation.情緒行為與其他"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="input-group">
                  <span class="input-group-text fs-4">阿肯巴克<br />衡鑑結果</span>
                  <div class="form-control d-flex flex-wrap align-items-start p-3">
                    <div class="row">
                      <div class="mb-2 fs-4 text-info">DSM導向量尺</div>
                      <div class="col-md-3" v-for="item in Achenback" :key="item">
                        <label class="form-label fs-5"><b>{{ item }}</b></label>
                        <select :id="item" class="form-select mb-2 me-2" v-model="Evaluation[item]" v-bind:style="{
                          'background-color': getBackgroundColor(
                            Evaluation[item]
                          ),
                        }">
                          <option :value="0" selected>請選擇</option>
                          <option v-for="select in AchenbackSelect" :key="select.id" :value="select.id">
                            {{ select.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-2 fs-4 text-info">症候群量尺</div>
                      <div class="col-md-3" v-for="item1 in Achenback1" :key="item1">
                        <label class="form-label fs-5"><b>{{ item1 }}</b></label>
                        <select :id="item1" class="form-select mb-2 me-2" v-model="Evaluation[item1]" v-bind:style="{
                          'background-color': getBackgroundColor(
                            Evaluation[item1]
                          ),
                        }">
                          <option :value="0" selected>請選擇</option>
                          <option v-for="select in AchenbackSelect" :key="select.id" :value="select.id">
                            {{ select.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="input-group">
                  <span class="input-group-text fs-4">轉介期待</span>
                  <textarea id="Referral_Expect" class="form-control" rows="4" maxlength="1000"
                    v-model="Evaluation.Referral_Expect"></textarea>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="input-group">
                  <span class="input-group-text fs-4">其他&emsp;&emsp;</span>
                  <textarea class="form-control" rows="4" maxlength="1000" v-model="Evaluation.其他"></textarea>
                </div>
              </div>
              <div class="col-12 mb-3" v-if="props.casestatus !== 205">
                <div class="input-group">
                  <span class="input-group-text fs-4">評估結果</span>
                  <div id="評估結果" class="form-control d-flex flex-column align-items-start p-3 fs-5">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="入校服務" value="1" v-model="Evaluation.評估結果" />
                      <label class="form-check-label" for="入校服務">開案，安排心理師入校服務</label>

                      <!-- 使用 Bootstrap 的 row 和 col 來實現響應式排列 -->
                      <div class="row" v-if="Evaluation.評估結果 == '1'">
                        <!-- 在桌面版本上，這個 div 會佔 6 列；在手機版本上，它會佔 12 列（即全寬） -->
                        <div class="col-12 col-md-6">
                          <label for="補助次數" class="form-label text-muted mt-md-2">補助次數:</label>
                        </div>
                        <!-- 在桌面版本上，這個 div 會佔 6 列；在手機版本上，它會佔 12 列（即全寬） -->
                        <div class="col-12 col-md-6">
                          <input type="number" class="form-control" min="0" id="補助次數" v-model="Evaluation.補助次數" />
                        </div>
                      </div>
                      <div class="row" v-if="Evaluation.評估結果 == '1'">
                        <!-- 在桌面版本上，這個 div 會佔 6 列；在手機版本上，它會佔 12 列（即全寬） -->
                        <div class="col-12 col-md-6">
                          <label for="間接補助次數" class="form-label text-muted mt-md-2">間接補助次數:</label>
                        </div>
                        <!-- 在桌面版本上，這個 div 會佔 6 列；在手機版本上，它會佔 12 列（即全寬） -->
                        <div class="col-12 col-md-6">
                          <input type="number" class="form-control" min="0" id="間接補助次數" v-model="Evaluation.間接補助次數" />
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="轉介其他服務資源" value="2" v-model="Evaluation.評估結果" />
                      <label class="form-check-label" for="轉介其他服務資源">轉介其他服務資源</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="不開案" value="3" v-model="Evaluation.評估結果" />
                      <label class="form-check-label" for="不開案">心理師入校評估後不開案</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="mt-4 d-flex align-items-center justify-content-center"
          v-if="props.casestatus == 205 || props.casestatus == 208">
          <button type="button" class="btn btn-outline-primary btn-lg ms-2" @click="AddEvaluation()">
            存檔
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-light-green {
  background-color: rgba(0, 128, 0, 0.2);
  /* 綠色，透明度 20% */
}

.bg-light-red {
  background-color: rgba(255, 0, 0, 0.2);
  /* 紅色，透明度 20% */
}

.bg-light-yellow {
  background-color: rgba(255, 255, 0, 0.2);
  /* 黃色，透明度 20% */
}
</style>
